<script>
import Vue from 'vue'
import GlobalVue from '../../helper/Global.vue'
Vue.component("ModalSearch", ()=>import("@frontend/modal/ModalSearch.vue"))
Vue.component("ModalLegalAge", ()=>import("@frontend/modal/ModalLegalAge.vue"))
Vue.component("ModalCampaign", ()=>import("@frontend/modal/ModalCampaign.vue"))
Vue.component("ModalAccountSetting", ()=>import("@frontend/modal/ModalAccountSetting.vue"))
// Vue.component("ModalChangeProduct", ()=>import("@frontend/modal/ModalChangeProduct.vue"))
// Vue.component("ModalDesignerText", ()=>import("@frontend/modal/ModalDesignerText.vue"))
// Vue.component("ModalUpload", ()=>import("@frontend/modal/ModalUpload.vue"))
// Vue.component("ModalDesign", ()=>import("@frontend/modal/ModalDesign.vue"))

export default {
  extends: GlobalVue,
  props: {
  },
  data() {
    return {
      modals: [],
    }
  },
  mounted(){
    global.VModal = this
  },
  methods:{
    openModal(name, params, callback){
      var modals = this.modals.filter(x=>{ return x.name == name })
      if(!modals.length){
        this.modals.push({name:name,params:params,callback:callback})
      }else{
        modals[0].params = params
        modals[0].callback = callback
        modals[0].comp.open(params)
      }
    },
    readyComponent(comp, v){
      v.comp = comp
      comp.open(v.params)
    },
    closeModal(){
      $(".modal").modal("hide");
    }
  }
}
</script>

<template>
  <div VModal>
    <component @ready="comp=>{readyComponent(comp, v)}" v-for="(v,k) in modals" :key="k" :is="v.name" @callback="v.callback" />
  </div>
</template>